import { ajvResolver } from "@hookform/resolvers/ajv";
import { Button, Container, Image, Title } from "@mantine/core";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { PasswordInput, TextInput } from "react-hook-form-mantine";

import Logo from "../assets/logo-02.svg";
import Login from "../components/Auth/Login";

export default function Index() {
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: ajvResolver({
      type: "object",
      properties: {
        email: { type: "string", minLength: 1 },
        password: { type: "string", minLength: 1 },
      },
      required: ["email", "password"],
    }),
  });

  const onSubmit = (data) => {
    setLoginData(data);
    reset();
  };

  return (
    <Container size={420}>
      <Image src={Logo} alt="congrs logo" />
      <Title>Welcome to Congrs</Title>
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        <TextInput
          label="Email"
          placeholder="Enter your email"
          type="text"
          name="email"
          control={control}
          withAsterisk
          mt="md"
        />
        <PasswordInput
          label="Password"
          placeholder="Enter your password"
          name="password"
          control={control}
          withAsterisk
          mt="md"
        />
        <Button type="submit" variant="gradient" fullWidth size="md" my={10}>
          Login
        </Button>
      </form>
      {loginData.email && loginData.password && <Login loginData={loginData} />}
    </Container>
  );
}
