import { IconEdit, IconPlus, IconPrinter } from "@tabler/icons-react";

// switch case that returns a button based on the action
const getActionButtonData = (action) => {
  switch (action) {
    case "create":
      return { label: "Create", icon: IconPlus, to: "/create" };
    case "edit":
      return { label: "Edit", icon: IconEdit, to: "/edit" };
    case "print":
      return { label: "Print", icon: IconPrinter, to: "/print" };
    default:
      return null;
  }
};

export default getActionButtonData;
