import { useApiMutation } from "../services/react-query";

export const useLogin = () =>
  useApiMutation({
    queryOptions: { queryKey: ["auth"], retry: 0 },
    fetchOptions: {
      url: "/auth/login",
      method: "POST",
    },
  });

export const useSendInviteMail = (userPublicId) =>
  useApiMutation({
    queryOptions: { queryKey: ["auth"] },
    fetchOptions: {
      url: `/auth/invite/${userPublicId}`,
      method: "POST",
    },
  });
