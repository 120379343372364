import { useState } from "react";
import { ActionIcon, Group, Text, Tooltip } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconActivity, IconEdit, IconTrash } from "@tabler/icons-react";
import { DataTable } from "mantine-datatable";
import { Link } from "react-router-dom";
import { useDeletePoll } from "../../api/polls";

export default function PollsTable({ data, isLoading, role, page, setPage }) {
  const [selectedPollId, setSelectedPollId] = useState(null);
  const { mutate } = useDeletePoll(selectedPollId);

  const deletePollById = (id) => {
    setSelectedPollId(id);
    modals.openConfirmModal({
      title: "Are you sure you want to delete poll?",
      labels: { confirm: "Yes", cancel: "Cancel" },
      onConfirm: () => mutate(id),
    });
  };
  return (
    <DataTable
      records={data?.data}
      columns={[
        { accessor: "id" },
        { accessor: "title" },
        { accessor: "description" },
        {
          accessor: "isVisible",
          render: (data) => <Text>{data?.isVisible ? "yes" : "no"}</Text>,
        },
        { accessor: "status" },
        {
          accessor: "sessionId",
          render: (data) => <Text>{data?.sessionId}</Text>,
        },
        { accessor: "workspaceId" },
        { accessor: "eventId" },
        {
          accessor: "actions",
          width: 150,
          render: (poll) => (
            <Group justify="center">
              <Tooltip label="Edit">
                <ActionIcon
                  size="md"
                  variant="light"
                  component={Link}
                  to={`${poll.id}/edit`}
                >
                  <IconEdit />
                </ActionIcon>
              </Tooltip>
              <Tooltip label="Delete" disabled>
                <ActionIcon
                  size="md"
                  variant="light"
                  color="red"
                  onClick={() => deletePollById(poll.id)}
                >
                  <IconTrash />
                </ActionIcon>
              </Tooltip>
              <Tooltip label="Results">
                <ActionIcon
                  size="md"
                  variant="light"
                  color="green"
                  component={Link}
                  to={`${poll.id}/results`}
                >
                  <IconActivity />
                </ActionIcon>
              </Tooltip>
            </Group>
          ),
        },
      ]}
      withTableBorder
      striped
      withColumnBorders
      highlightOnHover
      page={page}
      onPageChange={setPage}
      recordsPerPage={data?.pagination.perPage}
      totalRecords={data?.pagination.total}
      fetching={isLoading}
    />
  );
}
