import {
  IconBrush,
  IconCubeUnfolded,
  IconMessageChatbot,
  IconTimelineEvent,
  IconUsersGroup,
  IconCalendar,
  IconVersions,
  IconNotebook,
} from "@tabler/icons-react";

const links = [
  {
    path: "/app/workspaces",
    label: "Workspaces",
    icon: IconCubeUnfolded,
  },
  {
    path: "/app/events",
    label: "Events",
    icon: IconCalendar,
  },
  {
    path: "/app/users",
    label: "Users",
    icon: IconUsersGroup,
  },
  {
    path: "/app/events/customize",
    label: "Customization",
    icon: IconBrush,
  },
  {
    path: "/app/sessions",
    label: "Sessions",
    icon: IconTimelineEvent,
  },
  {
    path: "/app/sponsors",
    label: "Sponsors",
    icon: IconVersions,
  },
  {
    path: "/app/polls",
    label: "Polls",
    icon: IconMessageChatbot,
  },
  {
    path: "/app/pages",
    label: "Pages",
    icon: IconNotebook,
  },
];

export default links;
