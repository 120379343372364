import { Badge } from "@mantine/core";

const getColorByRole = (role) => {
  let color;

  switch (role) {
    case "sysAdmin":
      color = "red";
      break;
    case "wsAdmin":
      color = "green";
      break;
    case "attendee":
      color = "blue";
      break;
    default:
      color = "grey";
      break;
  }

  return color;
};

function RoleBadges({ roleName, ...props }) {
  const color = getColorByRole(roleName);
  return (
    <Badge color={color} variant="light" {...props}>
      {roleName}
    </Badge>
  );
}

export default RoleBadges;
