import { notifications } from "@mantine/notifications";
import { QueryClient, useMutation, useQuery } from "@tanstack/react-query";
import { callApi } from "./api";

export const queryClient = new QueryClient();

const appendSearchParams = (url, params) => {
  const searchParams = new URLSearchParams(params);

  return `${url}?${searchParams.toString()}`;
};

/**
 * @typedef {Object} FetchOptions
 * @property {string} url - The URL for the query.
 * @property {string} method - The HTTP method for the query.
 * @property {Object} body - The body of the request.
 * @property {Object} params - The query parameters.
 */

/**
 * Custom hook for making authenticated API queries using react-query.
 * @param {Object} config - The configuration for the query.
 * @param {import("@tanstack/react-query").UseQueryOptions} config.queryOptions - The configuration options for the query.
 * @param {FetchOptions} config.fetchOptions - The fetch options for the query.
 * @returns {import("@tanstack/react-query").UseQueryResult} - The result of the query.
 */
export const useApiQuery = (config) => {
  const { fetchOptions } = config;

  if (fetchOptions.params) {
    fetchOptions.url = appendSearchParams(
      fetchOptions.url,
      fetchOptions.params
    );
  }

  return useQuery({
    ...config.queryOptions,
    queryFn: async () => {
      const token = localStorage.getItem("token");

      return callApi({
        url: fetchOptions.url,
        method: fetchOptions.method,
        body: fetchOptions.body,
        token,
      });
    },
  });
};

/**
 * Custom hook for making authenticated API mutations using react-query.
 * @param {Object} config - The configuration for the mutation.
 * @param {import("@tanstack/react-query").UseMutationOptions} config.mutationOptions - The configuration options for the mutation.
 * @param {FetchOptions} config.fetchOptions - The fetch options for the mutation.
 * @param {String} config.invalidateQueryKey - The query key to invalidate after the mutation success.
 * @param {Boolean} config.hideNotification - Whether to hide the notification after the mutation.
 * @param {import("@mantine/notifications").NotificationsProps} config.successMessage - The success message to show after the mutation.
 * @returns {import("@tanstack/react-query").UseMutationResult} - The result of the mutation.
 */

export const useApiMutation = (config) => {
  const { fetchOptions } = config;

  if (fetchOptions.params) {
    fetchOptions.url = appendSearchParams(
      fetchOptions.url,
      fetchOptions.params
    );
  }

  return useMutation({
    ...config.mutationOptions,
    mutationFn: async (body) => {
      const token = localStorage.getItem("token");

      return callApi({
        url: fetchOptions.url,
        method: fetchOptions.method,
        body,
        token,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(config.invalidateQueryKey);

      if (!config.hideNotification) {
        const successMessage = config.successMessage || {
          title: "Operation successful",
          color: "green",
        };

        notifications.show(successMessage);
      }
    },
    onError: (error) => {
      notifications.show({
        title: "Error",
        message: error.message,
        color: "red",
      });
    },
  });
};
