import { DataTable } from "mantine-datatable";
import { useParams } from "react-router-dom";

import { useGetWorkspaceUsers } from "../../../api/workspace";
import RemoveUserModalButton from "./RemoveUserModal";

export const WorkspaceMembersTable = () => {
  const { id } = useParams();
  const { data: workspaceUsers, isPending } = useGetWorkspaceUsers(id);

  return (
    <DataTable
      records={workspaceUsers}
      columns={[
        {
          accessor: "name",
          render: (row) => `${row.firstName} ${row.lastName}`,
        },
        { accessor: "email" },
        { accessor: "role" },
        {
          accessor: "actions",
          title: "Actions",
          textAlign: "right",
          render: (row) => <RemoveUserModalButton userId={row.id} />,
        },
      ]}
      withTableBorder
      fetching={isPending}
      minHeight={150}
      mt={16}
    />
  );
};
