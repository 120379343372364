import { Container, Text, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useContext, useState } from "react";

import { useGetWorkspaces } from "../api/workspace";
import WorkspacesCards from "../components/Workspaces/WorkspacesCards";
import WorkspacesTable from "../components/Workspaces/WorkspacesTable";
import { AuthContext } from "../services/context";

function Workspaces() {
  const { user } = useContext(AuthContext);
  const [page, setPage] = useState(1);

  const { data, isPending: isLoading } = useGetWorkspaces(page);

  const isSysAdmin = user.role === "sysAdmin";
  const smallScreen = useMediaQuery("(max-width: 640px)");

  if (!data || data?.data.length === 0) {
    return (
      <Container>
        <Title order={2}>No workspaces found.</Title>
        <Text c="red">Please contact support.</Text>
      </Container>
    );
  }

  return (
    <>
      {smallScreen ? (
        <WorkspacesCards
          data={data}
          isLoading={isLoading}
          isSysAdmin={isSysAdmin}
        />
      ) : (
        <WorkspacesTable
          data={data}
          isLoading={isLoading}
          isSysAdmin={isSysAdmin}
          page={page}
          setPage={setPage}
        />
      )}
    </>
  );
}

export default Workspaces;
