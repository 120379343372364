import { ActionIcon, Group, ThemeIcon, Tooltip } from "@mantine/core";
import { IconChecks, IconEdit } from "@tabler/icons-react";
import { DataTable } from "mantine-datatable";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useGetUsers } from "../../api/user";
import RoleBadges from "../RoleBadges";
import DeleteUserModal from "./DeleteUserModal";
import SendInviteMailModal from "./SendInviteMailModal";

function UsersTable() {
  const [page, setPage] = useState(1);

  const { data, isLoading } = useGetUsers({ page, limit: 100 });

  const navigate = useNavigate();

  return (
    <DataTable
      records={data?.data}
      columns={[
        { accessor: "firstName" },
        { accessor: "lastName" },
        {
          accessor: "email",
          render: (row) => (
            <Group justify="space-between">
              {row.email}
              {row.password ? (
                <Tooltip label="User registered">
                  <ThemeIcon color="green" variant="subtle" size="sm">
                    <IconChecks />
                  </ThemeIcon>
                </Tooltip>
              ) : (
                <SendInviteMailModal user={row} />
              )}
            </Group>
          ),
        },
        {
          accessor: "role",
          render: (row) => <RoleBadges roleName={row.role} />,
        },
        {
          accessor: "actions",
          render: (row) => {
            return (
              <Group>
                <ActionIcon
                  color="blue"
                  variant="light"
                  title="Edit"
                  onClick={() => navigate(`/app/users/${row.id}`)}
                >
                  <IconEdit />
                </ActionIcon>
                <DeleteUserModal userId={row.id} />
              </Group>
            );
          },
        },
      ]}
      withTableBorder
      striped
      withColumnBorders
      highlightOnHover
      page={page}
      onPageChange={setPage}
      recordsPerPage={data?.pagination.perPage}
      totalRecords={data?.pagination.total}
      fetching={isLoading}
      minHeight={300}
    />
  );
}

export default UsersTable;
