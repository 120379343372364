import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useLogin } from "../../api/auth";
import { AuthContext } from "../../services/context";
import LoadingPage from "../common/LoadingPage";

function Login({ loginData }) {
  const navigate = useNavigate();

  const { setUser } = useContext(AuthContext);

  const { mutate, data, isSuccess, isLoading, isError, error } = useLogin();

  useEffect(() => {
    mutate(loginData);
  }, [mutate, loginData]);

  useEffect(() => {
    if (isLoading) {
      return <LoadingPage />;
    }
    if (isError) {
      console.log("Login error", error);
      navigate("/");
    }
    if (isSuccess && data) {
      localStorage.setItem("token", data.token);
      setUser(data.user);
      navigate("/app");
    }
  }, [isError, isSuccess, isLoading, data, setUser, navigate, error]);
}

export default Login;
