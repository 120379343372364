import { useApiMutation, useApiQuery } from "../services/react-query";

export const useGetWorkspaces = (page = 1) =>
  useApiQuery({
    queryOptions: { queryKey: ["workspaces", { page }] },
    fetchOptions: { url: `/workspaces?page=${page}`, method: "GET" },
  });

export const useGetWorkspace = (id) =>
  useApiQuery({
    queryOptions: { queryKey: ["workspace", { id }], enabled: !!id },
    fetchOptions: { url: `/workspaces/${id}`, method: "GET" },
  });

export const usePatchWorkspace = ({ id, config }) =>
  useApiMutation({
    mutationOptions: { mutationKey: "usePatchWorkspace", enabled: !!id },
    fetchOptions: { url: `/workspaces/${id}`, method: "PATCH" },
    invalidateQueryKey: "workspaces",
    successMessage: { title: "Workspace has been updated", color: "green" },
    ...config,
  });

export const useCreateWorkspace = () =>
  useApiMutation({
    mutationOptions: { mutationKey: "useCreateWorkspace" },
    fetchOptions: { url: "/workspaces", method: "POST" },
    invalidateQueryKey: "workspaces",
    successMessage: { title: "Workspace has been created", color: "green" },
  });

export const useGetWorkspaceUsers = (id) =>
  useApiQuery({
    queryOptions: { queryKey: ["workspaceUsers", { id }], enabled: !!id },
    fetchOptions: { url: `/workspaces/${id}/users`, method: "GET" },
  });

export const useAddUserToWorkspace = ({ workspaceId }) =>
  useApiMutation({
    mutationOptions: { mutationKey: "useAddUserToWorkspace" },
    fetchOptions: {
      url: `/workspaces/${workspaceId}/users`,
      method: "POST",
    },
    invalidateQueryKey: "workspaceUsers",
    successMessage: {
      title: "User has been added to workspace",
      color: "green",
    },
  });

export const useRemoveUserFromWorkspace = ({ workspaceId, userId }) =>
  useApiMutation({
    mutationOptions: { mutationKey: "useRemoveUserFromWorkspace" },
    fetchOptions: {
      url: `/workspaces/${workspaceId}/users/${userId}`,
      method: "DELETE",
    },
    invalidateQueryKey: "workspaceUsers",
    successMessage: {
      title: "User has been removed from workspace",
      color: "green",
    },
  });

export const useDeleteWorkspace = (id) =>
  useApiMutation({
    mutationOptions: { mutationKey: "useDeleteWorkspace" },
    fetchOptions: { url: `/workspaces/${id}`, method: "DELETE" },
    invalidateQueryKey: "workspaces",
    successMessage: { title: "Workspace has been deleted", color: "green" },
  });
