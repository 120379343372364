import { Loader, Text } from "@mantine/core";
import { useContext } from "react";
import { useParams } from "react-router-dom";

import { useGetSession } from "../../../api/session";
import { useGetSponsors } from "../../../api/sponsors";
import { useGetUsers } from "../../../api/user";
import { getCurrentWorkspace } from "../../../services/userConfig";
import { AuthContext } from "../../../services/context";
import EditForm from "./Form";

export default function EditSession() {
  const { id } = useParams();
  const { user } = useContext(AuthContext);

  const currentWorkspace = getCurrentWorkspace(user);

  const enableActions = user.role === "sysAdmin" || user.role === "wsAdmin";
  const {
    data: responseData,
    isPending,
    isSuccess,
    isError,
    error,
  } = useGetSession(id);

  const { data: speakerOptions, isSuccess: userSuccess } = useGetUsers();
  const { data: sponsors, isSuccess: sponsorSuccess } = useGetSponsors({
    workspaceId: currentWorkspace.id,
    eventId: currentWorkspace.eventId,
  });

  let content;
  if (isPending) {
    content = <Loader mt={10} />;
  } else if (isError) {
    content = <Text c="red">{error.message}</Text>;
  } else if (isSuccess && userSuccess && sponsorSuccess) {
    const sponsorOptions = sponsors?.data.map((s) => ({
      label: s.name,
      value: s.id.toString(),
    }));

    content = (
      <EditForm
        data={responseData}
        enableActions={enableActions}
        speakerOptions={speakerOptions}
        sponsorOptions={sponsorOptions}
      />
    );
  }

  return content;
}
