import { Avatar, Button, Container, Fieldset, Text } from "@mantine/core";
import { useForm } from "react-hook-form";
import { Checkbox, TextInput, Textarea } from "react-hook-form-mantine";
import { useParams } from "react-router-dom";

import { useGetUser, useUpdateUser } from "../../api/user";
import DeleteProfilePicture from "../Buttons/DeleteProfilePicture";

const EditUserWrapper = () => {
  const { id } = useParams();

  const { data: user, isPending, isError, error } = useGetUser(id);

  if (isPending) {
    return <Text>Loading...</Text>;
  }

  if (isError) {
    return <Text color="red">{error.message}</Text>;
  }

  return <EditUser user={user} />;
};

const EditUser = ({ user }) => {
  const defaultValues = {
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    email: user?.email || "",
    occupation: user?.occupation || "",
    biography: user?.biography || "",
    discoverable: user?.discoverable || false,
  };

  const { mutate, isPending: mutatePending } = useUpdateUser({ id: user.id });

  const {
    control,
    handleSubmit,
    formState: { isDirty, dirtyFields },
    reset,
  } = useForm({
    defaultValues,
  });

  const onSubmit = (data) => {
    const dirtyData = Object.keys(dirtyFields).reduce((acc, key) => {
      acc[key] = data[key];
      return acc;
    }, {});

    mutate(dirtyData);

    reset({ ...defaultValues, ...dirtyData });
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Fieldset legend="Personal information">
          <Avatar
            src={user?.picture}
            size={150}
            radius="xl"
            alt="Profile picture"
          >
            {user?.firstName?.[0]}
            {user?.lastName?.[0]}
          </Avatar>
          {user?.picture && (
            <DeleteProfilePicture userId={user?.id} my="md" size="sm" />
          )}
          <TextInput
            mt="md"
            name="firstName"
            control={control}
            placeholder="John"
            label="First name"
          />
          <TextInput
            mt="md"
            name="lastName"
            control={control}
            placeholder="Doe"
            label="Last name"
          />
          <TextInput
            mt="md"
            name="email"
            control={control}
            placeholder="john@doe.com"
            label="Email"
            description="Use with caution, editing email will require re-verification"
          />
          <TextInput
            mt="md"
            name="occupation"
            control={control}
            placeholder="Software Engineer @ RoundCube"
            label="Occupation and company"
          />
          <Textarea
            mt="md"
            name="biography"
            control={control}
            placeholder="Tell us about yourself"
            label="Biography"
          />
          <Checkbox
            mt="md"
            name="discoverable"
            control={control}
            label="Discoverable"
            description="Allow other users to find you by searching your name"
          />
        </Fieldset>
        <Button
          mt="md"
          type="submit"
          variant="light"
          disabled={!isDirty}
          loading={mutatePending}
        >
          Save changes
        </Button>
      </form>
    </Container>
  );
};

export default EditUserWrapper;
