import { Button, Container, Paper, Title } from "@mantine/core";
import { TextInput } from "react-hook-form-mantine";
import { Controller, useForm } from "react-hook-form";

import PagesEditor from "./PagesEditor";
import { useCreatePage } from "../../api/pages";
import { getCurrentWorkspace } from "../../services/userConfig";
import { useContext } from "react";
import { AuthContext } from "../../services/context";
import IconSelect from "../IconSelect";

const CreatePage = () => {
  const { mutate, isSuccess, isPending } = useCreatePage();

  const { user } = useContext(AuthContext);
  const { eventId } = getCurrentWorkspace(user);

  const { handleSubmit, control, watch } = useForm({
    defaultValues: {
      title: "",
      icon: "",
      content: "",
    },
  });

  const icon = watch("icon");

  const onSubmit = (data) => {
    mutate({
      ...data,
      eventId,
    });
  };

  return (
    <Container>
      <Title order={1}>Create page</Title>
      <Paper withBorder shadow="md" p="sm" mt="sm" radius="md">
        <form onSubmit={handleSubmit((data) => onSubmit(data))}>
          <TextInput
            label="Page title"
            placeholder="Sample page"
            control={control}
            name="title"
          />
          <IconSelect
            label="Icon"
            placeholder="Select icon"
            control={control}
            name="icon"
            currentIcon={icon}
          />
          <Controller
            name="content"
            control={control}
            render={({ field: { onChange } }) => (
              <PagesEditor onChange={onChange} />
            )}
          />
          <Button type="submit" loading={isPending} disabled={isSuccess}>
            Create page
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default CreatePage;
