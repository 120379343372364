import { Avatar, Button, Container, Group, Text, Title } from "@mantine/core";
import { Link } from "react-router-dom";
import { useGetCurrentUser } from "../api/user";
import LogOutButton from "./LogOutButton";
import RoleBadges from "./RoleBadges";

const Profile = () => {
  const { data } = useGetCurrentUser();

  return (
    <Container>
      <Title order={1}>Profile</Title>
      <Group py="sm">
        <Avatar
          src={data?.picture}
          alt={data?.name}
          size="xl"
          radius="xl"
          style={{ marginRight: 20 }}
        >
          {data?.firstName[0]}
          {data?.lastName[0]}
        </Avatar>
        <div>
          <Text size="lg" fw={700}>
            {data?.firstName} {data?.lastName}
          </Text>
          <Text>{data?.occupation}</Text>
          <Text>{data?.email}</Text>
          <RoleBadges roleName={data?.role} />
        </div>
      </Group>

      {data?.biography && (
        <>
          <Title order={2}>Biography</Title>
          <Text>{data.biography}</Text>
        </>
      )}
      <Group py="sm">
        <LogOutButton />
        <Button variant="light" component={Link} to="/app/profile/edit">
          Edit profile
        </Button>
      </Group>
    </Container>
  );
};

export default Profile;
