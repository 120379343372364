import { Loader, Text } from "@mantine/core";

import { useContext } from "react";
import { useGetEvent } from "../../../api/event";
import { getCurrentWorkspace } from "../../../services/userConfig";
import { AuthContext } from "../../../services/context";
import CustomizeEvent from "./CustomizeEvent";

export default function Customize() {
  const { user } = useContext(AuthContext);
  const currentWorkspace = getCurrentWorkspace(user);

  const { data, isPending, isSuccess, isError, error } = useGetEvent(
    currentWorkspace?.eventId
  );

  if (isPending) {
    return <Loader mt={10} />;
  }
  if (isError) {
    return <Text c="red">{error.message}</Text>;
  }
  return <CustomizeEvent data={data} />;
}
