import { ajvResolver } from "@hookform/resolvers/ajv";
import {
  Button,
  Container,
  Fieldset,
  MultiSelect,
  Paper,
  Select,
  Text,
  TextInput as TextInputMantine,
} from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import "@mantine/dates/styles.css";
import { IconPlus, IconX } from "@tabler/icons-react";
import { useContext, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { TextInput } from "react-hook-form-mantine";

import { useCreateSession } from "../../api/session";
import { useGetSponsors } from "../../api/sponsors";
import { useGetUsers } from "../../api/user";
import { AuthContext } from "../../services/context";
import { getCurrentWorkspace } from "../../services/userConfig";
import { formatDateTime } from "./utils/formatDateTime";

export default function CreateSession() {
  const { user } = useContext(AuthContext);
  const {
    data: userData,
    isPending: userPending,
    isError: userIsError,
    error: userError,
  } = useGetUsers();

  const currentWorkspace = getCurrentWorkspace(user);

  const {
    data: sponsors,
    isSuccess: sponsorsSuccess,
    isError: sponsorsIsError,
    error: sponsorsError,
  } = useGetSponsors({
    workspaceId: currentWorkspace.id,
    eventId: currentWorkspace.eventId,
  });

  const { mutate, isPending } = useCreateSession();

  let sponsorsOptions;
  if (sponsorsSuccess) {
    sponsorsOptions = sponsors.data.map((s) => ({
      label: s.name,
      value: s.id.toString(),
    }));
  }

  const enableActions = user?.role === "sysAdmin" || user?.role === "wsAdmin";

  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);

  const { register, control, handleSubmit, setValue } = useForm({
    defaultValues: {
      title: "",
      description: "",
      location: "",
      starting: "",
      ending: "",
      speakers: [],
      sponsorId: "",
      links: "",
    },
    resolver: ajvResolver({
      type: "object",
      properties: {
        title: { type: "string", minLength: 1 },
        location: { type: "string", minLength: 3 },
      },
      required: ["title", "location", "starting", "ending"],
    }),
  });

  const { fields, append, remove } = useFieldArray({
    name: "links",
    control,
  });

  const onSubmit = (data) => {
    data.starting = formatDateTime(start.toString());
    data.ending = formatDateTime(end.toString());
    data.wsId = currentWorkspace.id;
    data.eventId = currentWorkspace.eventId;
    data.sponsorId = data.sponsorId ? Number(data.sponsorId) : null;

    data.speakers = { list: data.speakers.map((s) => Number(s)) };
    data.links = { list: data.links };

    mutate(data);
  };

  if (userPending) {
    return <Text>Loading...</Text>;
  }
  if (userIsError) {
    return <Text>Error while getting user data: {userError}</Text>;
  }
  if (sponsorsIsError) {
    return <Text>Error while getting sponsors data: {sponsorsError}</Text>;
  }

  return (
    <Container size={500}>
      <Paper withBorder shadow="md" p={16} mt={16} radius="md">
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            label="Title"
            placeholder="Title"
            type="text"
            name="title"
            control={control}
            withAsterisk
            mt="md"
            disabled={!enableActions}
          />
          <TextInput
            label="Description"
            placeholder="Description"
            type="text"
            name="description"
            control={control}
            mt="md"
            disabled={!enableActions}
          />
          <TextInput
            label="Location"
            placeholder="Location"
            type="text"
            name="location"
            control={control}
            withAsterisk
            mt="md"
            disabled={!enableActions}
          />
          <DateTimePicker
            label="Starting"
            placeholder="Starting"
            name="starting"
            onChange={setStart}
            withAsterisk
            mt="md"
            clearable
            disabled={!enableActions}
          />
          <DateTimePicker
            label="Ending"
            placeholder="Ending"
            name="ending"
            onChange={setEnd}
            withAsterisk
            mt="md"
            clearable
            disabled={!enableActions}
          />
          <MultiSelect
            checkIconPosition="left"
            data={userData?.slice(1).map((speaker) => ({
              label: `${speaker.firstName} ${speaker.lastName}, ${speaker.occupation}`,
              value: speaker.id.toString(),
            }))}
            label="Speakers"
            name="speakers"
            placeholder="Add speaker"
            mt="md"
            searchable
            nothingFoundMessage="Nothing found"
            onChange={(value) =>
              setValue("speakers", value, { shouldDirty: true })
            }
          />
          <Select
            label="Sponsor"
            name="sponsorId"
            control={control}
            placeholder="Add sponsor"
            data={sponsorsOptions}
            clearable
            searchable
            mt="md"
            onChange={(value) =>
              setValue("sponsorId", Number(value), { shouldDirty: true })
            }
          />

          <Fieldset legend="Links" mt="md">
            {fields.map((field, index) => (
              <Fieldset mt="md" key={field.id}>
                <TextInputMantine
                  label="Link title"
                  {...register(`links.${index}.title`)}
                />
                <TextInputMantine
                  label="Link URL"
                  {...register(`links.${index}.url`)}
                />

                <Button
                  mt={5}
                  size="xs"
                  style={{ backgroundColor: "red" }}
                  onClick={() => remove(index)}
                  leftSection={<IconX size={20} />}
                >
                  Remove link
                </Button>
              </Fieldset>
            ))}

            <Button
              mt={5}
              size="xs"
              style={{ justifySelf: "flex-end" }}
              onClick={() => append()}
              leftSection={<IconPlus size={20} />}
            >
              Add link
            </Button>
          </Fieldset>

          {!enableActions && (
            <Text size="md" c="red" mt="md">
              Only system admins and workspace admins can create new sessions!
            </Text>
          )}
          <Button
            type="submit"
            mt="md"
            loading={isPending}
            disabled={!enableActions}
          >
            Create session
          </Button>
        </form>
      </Paper>
    </Container>
  );
}
