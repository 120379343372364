import { ajvResolver } from "@hookform/resolvers/ajv";
import {
  Button,
  Container,
  FileInput,
  Flex,
  Image,
  Paper,
  Text,
} from "@mantine/core";
import { IconFileUpload } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { TextInput } from "react-hook-form-mantine";
import { modals } from "@mantine/modals";

import { usePatchEvent } from "../../../api/event";
import { useUpload } from "../../../api/r2";
import { toBase64 } from "../../../services/helper";
// This is how we fetch data from the bucket
// const fetchData = async () => {
//   const data = await fetch(
//     new Request(
//       "https://pub-45c81e40f4c74a34bacddebce6171d1d.r2.dev/header-example-image.jpg",
//       {
//         method: "GET",
//         mode: "no-cors",
//         headers: {
//           "Content-Type": "application/json",
//         },
//       }
//     )
//   );
// };

function CustomizeEvent({ data }) {
  const { config } = data;
  const { logoImage, headerImage, applicationIcon } = config || {};
  const name = data?.name;
  const [newLogoImage, setNewLogoImage] = useState(null);
  const [newHeaderImage, setNewHeaderImage] = useState(null);
  const [newApplicationIcon, setNewApplicationIcon] = useState(null);

  const { mutate, isSuccess, isPending: isLoading } = usePatchEvent(data?.id);

  const {
    mutate: upload,
    isSuccess: uploadSuccess,
    isError: uploadIsError,
    error: uploadError,
  } = useUpload();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isDirty, dirtyFields },
    reset,
  } = useForm({
    defaultValues: {
      name,
      logoImage: logoImage,
      headerImage: headerImage,
      applicationIcon: applicationIcon,
    },
    resolver: ajvResolver({
      type: "object",
      properties: {
        name: { type: "string", minLength: 1 },
      },
      required: ["name"],
    }),
  });

  useEffect(() => {
    reset({
      name,
      logoImage,
      headerImage,
      applicationIcon,
    });
  }, [reset, data]);

  const openImageModal = (img) => {
    modals.open({
      title: `${img.name}`,
      children: <Image src={img.src} style={{ maxWidth: 500 }} />,
    });
  };
  const onSubmit = (data) => {
    data.config = { ...config };
    if (dirtyFields.logoImage) {
      data.config.logoImage = `https://pub-45c81e40f4c74a34bacddebce6171d1d.r2.dev/${newLogoImage.name}`;
    }

    if (dirtyFields.headerImage) {
      data.config.headerImage = `https://pub-45c81e40f4c74a34bacddebce6171d1d.r2.dev/${newHeaderImage.name}`;
    }

    if (dirtyFields.applicationIcon) {
      data.config.applicationIcon = `https://pub-45c81e40f4c74a34bacddebce6171d1d.r2.dev/${newApplicationIcon.name}`;
    }

    mutate({ name: data.name, config: data.config });
  };

  return (
    <Container size={500}>
      <Paper withBorder shadow="md" p={16} mt={16} radius="md">
        <Text fw={500}>Event config</Text>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            label="Event title"
            type="text"
            name="name"
            defaultValue={name}
            control={control}
            withAsterisk
            mt="md"
          />

          <Flex
            justify="space-around"
            my={5}
            style={{ justifyContent: "center", gap: 15 }}
          >
            {logoImage && (
              <Image
                src={logoImage}
                style={{ maxWidth: 100, cursor: "zoom-in" }}
                onClick={() =>
                  openImageModal({ name: "Logo image", src: logoImage })
                }
              />
            )}
            <FileInput
              label="Logo image"
              name="logoImage"
              description="Logo that is shown on the homepage of the application."
              placeholder="Upload file"
              clearable
              leftSection={<IconFileUpload />}
              style={{ width: "50%" }}
              onChange={(value) => {
                setNewLogoImage(value);
                setValue("logoImage", newLogoImage, {
                  shouldDirty: true,
                });
              }}
            />
            <Button
              style={{ alignSelf: "end" }}
              disabled={!dirtyFields.logoImage}
              onClick={async () => {
                upload({
                  name: newLogoImage.name,
                  data: await toBase64(newLogoImage),
                });
              }}
            >
              Upload
            </Button>
          </Flex>

          <Flex
            justify="space-around"
            my={5}
            style={{ justifyContent: "center", gap: 15 }}
          >
            {headerImage && (
              <Image
                src={headerImage}
                style={{ maxWidth: 100, cursor: "zoom-in" }}
                onClick={() =>
                  openImageModal({ name: "Header image", src: headerImage })
                }
              />
            )}

            <FileInput
              label="Header image"
              name="headerImage"
              description="Image shown at the top of the mobile home page."
              placeholder="Upload file"
              clearable
              leftSection={<IconFileUpload />}
              style={{ width: "50%" }}
              onChange={(value) => {
                setNewHeaderImage(value);
                setValue("headerImage", newHeaderImage, {
                  shouldDirty: true,
                });
              }}
            />

            <Button
              style={{ alignSelf: "end" }}
              disabled={!newHeaderImage}
              onClick={async () => {
                upload({
                  name: newHeaderImage.name,
                  data: await toBase64(newHeaderImage),
                });
              }}
            >
              Upload
            </Button>
          </Flex>

          <Flex
            justify="space-around"
            my={5}
            style={{ justifyContent: "center", gap: 15 }}
          >
            {applicationIcon && (
              <Image
                src={applicationIcon}
                style={{ maxWidth: 100, cursor: "zoom-in" }}
                onClick={() =>
                  openImageModal({
                    name: "Application icon",
                    src: applicationIcon,
                  })
                }
              />
            )}
            <FileInput
              label="Application icon"
              name="applicationIcon"
              description="Icon used on the home screen of the users phone."
              placeholder="Upload file"
              clearable
              leftSection={<IconFileUpload />}
              style={{ width: "50%" }}
              onChange={(value) => {
                setNewApplicationIcon(value);
                setValue("applicationIcon", newApplicationIcon, {
                  shouldDirty: true,
                });
              }}
            />

            <Button
              style={{ alignSelf: "end" }}
              disabled={!dirtyFields.applicationIcon}
              onClick={async () => {
                upload({
                  name: newApplicationIcon.name,
                  data: await toBase64(newApplicationIcon),
                });
              }}
            >
              Upload
            </Button>
          </Flex>

          {uploadSuccess && (
            <Text size="sm" c="green" mt="sm">
              Upload successful. Please save event config changes.
            </Text>
          )}

          <Button
            type="submit"
            mt="md"
            loading={isLoading}
            disabled={!isDirty || isSuccess}
          >
            Save event config
          </Button>
        </form>

        {uploadIsError && (
          <Text size="md" c="red" mt="md">
            {uploadError.message}
          </Text>
        )}
      </Paper>
    </Container>
  );
}

export default CustomizeEvent;
