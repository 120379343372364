import { Loader, Text } from "@mantine/core";
import { useContext } from "react";
import { useParams } from "react-router-dom";

import { useGetWorkspace } from "../../../api/workspace";
import { AuthContext } from "../../../services/context";
import AddUserToWorkspace from "./AddUsersToWorkspace";
import EditForm from "./Form";

export default function EditWorkspaces() {
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const {
    data: responseData,
    isPending,
    isSuccess,
    isError,
    error,
  } = useGetWorkspace(id);
  const isSysAdmin = user?.role === "sysAdmin";

  let content;
  if (isPending) {
    content = <Loader mt={10} />;
  } else if (isError) {
    content = <Text c="red">{error.message}</Text>;
  } else if (isSuccess) {
    content = (
      <>
        <EditForm data={responseData} isSysAdmin={isSysAdmin} />
        <AddUserToWorkspace />
      </>
    );
  }

  return content;
}
