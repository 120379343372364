import { ajvResolver } from "@hookform/resolvers/ajv";
import {
  Badge,
  Button,
  Container,
  Fieldset,
  Flex,
  MultiSelect,
  Paper,
  Select,
  Text,
  TextInput as TextInputMantine,
} from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import "@mantine/dates/styles.css";
import { IconPlus, IconX } from "@tabler/icons-react";
import { useFieldArray, useForm } from "react-hook-form";
import { TextInput, Textarea } from "react-hook-form-mantine";

import { usePatchSession, useCancelSession } from "../../../api/session";
import { formatDateTime } from "../utils/formatDateTime";
import deleteModal from "../../deleteModal";

function Form({ data, enableActions, speakerOptions, sponsorOptions }) {
  const {
    id,
    title,
    description,
    starting,
    ending,
    location,
    speakers,
    sponsorId,
    links,
  } = data;

  const eventSpeakers = speakerOptions?.filter((user) =>
    data.speakers?.list?.includes(user.id)
  );

  const defaultSpeakers = eventSpeakers?.map((s) => ({
    label: `${s.firstName} ${s.lastName}, ${s.occupation}`,
    value: s.id.toString(),
  }));

  const defaultSponsor = sponsorOptions?.find(
    (s) => Number(s.value) === sponsorId
  );

  const { mutate, isSuccess, isPending: isLoading } = usePatchSession(id);

  const {
    mutate: cancel,
    isError: isCancelError,
    isSuccess: isCancelSuccess,
    error: cancelError,
  } = useCancelSession(id, data.canceledAt !== null);

  const {
    register,
    control,
    handleSubmit,
    formState: { isDirty, dirtyFields },
    setValue,
  } = useForm({
    defaultValues: {
      title: title,
      description: description,
      location: location,
      starting: starting,
      ending: ending,
      speakers: speakers?.list,
      links: links?.list,
    },
    resolver: ajvResolver({
      type: "object",
      properties: {
        title: { type: "string", minLength: 1 },
        location: { type: "string", minLength: 3 },
      },
      required: ["title", "location", "starting", "ending"],
    }),
  });

  const { fields, append, remove } = useFieldArray({
    name: "links",
    control,
  });

  const onSubmit = (data) => {
    if (dirtyFields.starting) {
      data.starting = formatDateTime(data.starting.toString());
    }
    if (dirtyFields.ending) {
      data.ending = formatDateTime(data.ending.toString());
    }

    data.speakers = JSON.stringify({
      list: data.speakers?.map((s) => Number(s)),
    });

    data.links = JSON.stringify({ list: data.links });
    mutate(data);
  };
  return (
    <Container size={500}>
      <Paper withBorder shadow="md" p={16} mt={16} radius="md">
        {data.canceledAt !== null && (
          <Badge color="red" variant="dot" size="md" ta="right">
            Canceled
          </Badge>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            label="Title"
            type="text"
            name="title"
            defaultValue={title}
            control={control}
            withAsterisk
            mt="md"
            disabled={!enableActions}
          />

          <Textarea
            label="Description"
            type="text"
            name="description"
            defaultValue={description}
            control={control}
            mt="md"
            disabled={!enableActions}
          />

          <TextInput
            label="Location"
            type="text"
            name="location"
            defaultValue={location}
            control={control}
            withAsterisk
            mt="md"
            disabled={!enableActions}
          />

          <DateTimePicker
            label="Starting"
            defaultValue={new Date(starting)}
            name="starting"
            onChange={(value) =>
              setValue("starting", value, { shouldDirty: true })
            }
            control={control}
            withAsterisk
            mt="md"
            clearable
            disabled={!enableActions}
          />

          <DateTimePicker
            label="Ending"
            defaultValue={new Date(ending)}
            name="ending"
            onChange={(value) =>
              setValue("ending", value, { shouldDirty: true })
            }
            control={control}
            withAsterisk
            mt="md"
            clearable
            disabled={!enableActions}
          />

          <MultiSelect
            checkIconPosition="left"
            data={speakerOptions?.slice(1).map((speaker) => ({
              label: `${speaker.firstName} ${speaker.lastName}, ${speaker.occupation}`,
              value: speaker.id.toString(),
            }))}
            defaultValue={defaultSpeakers?.map((s) => s.value)}
            label="Speakers"
            name="speakers"
            placeholder="Add speaker"
            mt="md"
            searchable
            nothingFoundMessage="Nothing found"
            onChange={(value) =>
              setValue("speakers", value, { shouldDirty: true })
            }
          />

          <Select
            label="Sponsor"
            name="sponsorId"
            control={control}
            data={sponsorOptions}
            defaultValue={defaultSponsor?.value}
            clearable
            searchable
            nothingFoundMessage="Nothing found"
            mt="md"
            onChange={(value) =>
              setValue("sponsorId", Number(value), { shouldDirty: true })
            }
          />

          <Fieldset legend="Links" mt="md">
            {fields.map((field, index) => (
              <Fieldset mt="md" key={field.id}>
                <TextInputMantine
                  label="Link title"
                  {...register(`links.${index}.title`)}
                />
                <TextInputMantine
                  label="Link URL"
                  {...register(`links.${index}.url`)}
                />

                <Button
                  mt={5}
                  size="xs"
                  style={{ backgroundColor: "red" }}
                  onClick={() => remove(index)}
                  leftSection={<IconX size={20} />}
                >
                  Remove link
                </Button>
              </Fieldset>
            ))}

            <Button
              mt={5}
              size="xs"
              style={{ justifySelf: "flex-end" }}
              onClick={() => append()}
              leftSection={<IconPlus size={20} />}
            >
              Add link
            </Button>
          </Fieldset>

          {!enableActions && (
            <Text size="md" c="red" mt="md">
              Only system admins can edit sessions!
            </Text>
          )}

          <Flex justify="space-between" mt="md">
            <Button
              type="submit"
              loading={isLoading}
              disabled={!enableActions || !isDirty || isSuccess}
            >
              Edit session
            </Button>

            <Button
              size="sm"
              color="red"
              onClick={() =>
                deleteModal(
                  data,
                  `${data.canceledAt ? "restore" : "cancel"} '${data.title}' session`,
                  cancel,
                  isCancelError,
                  cancelError,
                  isCancelSuccess
                )
              }
            >
              {data.canceledAt === null ? "Cancel session" : "Restore session"}
            </Button>
          </Flex>
        </form>
      </Paper>
    </Container>
  );
}

export default Form;
