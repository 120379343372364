export const deepTransform = (data, fn) => {
  if (Array.isArray(data)) {
    return data.map((item) => deepTransform(item, fn));
  }
  if (
    data !== null &&
    data !== undefined &&
    typeof data === 'object' &&
    !(data instanceof Date)
  ) {
    const keys = Object.keys(data);
    return keys.reduce((acc, key) => {
      acc[key] = deepTransform(data[key], fn);
      return acc;
    }, {});
  }
  return fn(data);
};

export const sanitize = (src) => {
  if (src === null || src === undefined) {
    return undefined;
  }

  if (src instanceof Date) {
    return src.toISOString();
  }

  if (typeof src !== 'string') {
    return src;
  }

  // Trims the whitespace from the string
  const trimmed = src.trim();

  // Removes empty strings
  if (trimmed === '') {
    return undefined;
  }

  return trimmed;
};

export const deepSanitize = (data) => deepTransform(data, sanitize);
