import { ajvResolver } from "@hookform/resolvers/ajv";
import { Button, Container, Paper, Text } from "@mantine/core";
import { useContext } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { Checkbox, Select, TextInput, Textarea } from "react-hook-form-mantine";
import { v4 as uuidv4 } from "uuid";
import { useGetSessions } from "../../api/session";
import { AuthContext } from "../../services/context";
import { getCurrentWorkspace } from "../../services/userConfig";

import { useCreatePoll } from "../../api/polls";

export default function CreatePoll() {
  const { user } = useContext(AuthContext);
  const isSysAdmin = user?.role === "sysAdmin";
  const currentWorkspace = getCurrentWorkspace(user);
  const { data: sessionsData } = useGetSessions({
    workspaceId: currentWorkspace.id,
    eventId: currentWorkspace.eventId,
  });

  const { control, handleSubmit } = useForm({
    defaultValues: {
      title: "",
      description: "",
      options: [{ id: uuidv4(), title: "" }],
      workspaceId: currentWorkspace.id,
      eventId: currentWorkspace.eventId,
      isVisible: true,
      status: "open",
      areResultsVisible: true,
    },
    resolver: ajvResolver({
      type: "object",
      properties: {
        title: { type: "string", minLength: 1 },
        description: { type: "string" },
        isVisible: { type: "boolean" },
        status: { type: "string", enum: ["open", "closed"] },
        options: {
          type: "array",
          items: {
            type: "object",
            properties: {
              id: { type: "string", minLength: 1 },
              title: { type: "string" },
            },
            required: ["title", "id"],
          },
        },
      },
      required: ["title"],
    }),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "options",
  });

  const { mutate, isPending } = useCreatePoll();

  const onSubmit = (data) => {
    mutate(data);
  };

  return (
    <Container size={500}>
      <Paper withBorder shadow="md" p={16} mt={16} radius="md">
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            label="Question"
            placeholder="question"
            type="text"
            name="title"
            control={control}
            withAsterisk
            mt="md"
            disabled={!isSysAdmin}
          />
          {!isSysAdmin && (
            <Text size="md" c="red" mt="md">
              Only system admins can create new workspaces!
            </Text>
          )}
          <Textarea
            label="Description"
            placeholder="Description"
            type="text"
            name="description"
            control={control}
            mt="md"
            disabled={!isSysAdmin}
          />
          <div>
            <div>
              <div style={{ marginTop: 20 }}>
                <label>Options</label>
                {fields.map((field, index) => (
                  <div
                    key={field.id}
                    style={{ display: "flex", marginBottom: 20 }}
                  >
                    <Button
                      color="gray"
                      type="button"
                      onClick={() => remove(index)}
                    >
                      -
                    </Button>
                    <Button
                      color="gray"
                      style={{ marginLeft: 10, marginRight: 10 }}
                      type="button"
                      onClick={() => append({ id: uuidv4(), title: "" })}
                    >
                      +
                    </Button>
                    <TextInput
                      placeholder="Option"
                      type="text"
                      name={`options.${index}.title`}
                      control={control}
                    />
                  </div>
                ))}
                <Select
                  control={control}
                  label="Status"
                  name="status"
                  data={["open", "closed"]}
                />
                {/* TODO: we should make sessions filtering by event id */}
                <Select
                  control={control}
                  label="Session"
                  name="sessionId"
                  data={sessionsData?.map((s) => ({
                    value: `${s.id}`,
                    label: s.title,
                  }))}
                />
                <Checkbox
                  label="Is Visible"
                  name="isVisible"
                  mt="md"
                  defaultChecked={true}
                  control={control}
                />
                <Checkbox
                  label="Results Visible"
                  name="areResultsVisible"
                  mt="md"
                  defaultChecked={true}
                  control={control}
                />
              </div>
            </div>
          </div>
          <Button
            type="submit"
            mt="md"
            loading={isPending}
            disabled={!isSysAdmin}
            onClick={() => {
              handleSubmit(onSubmit);
            }}
          >
            Create Poll
          </Button>
        </form>
      </Paper>
    </Container>
  );
}
