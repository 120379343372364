import { NavLink } from "react-router-dom";
import classes from "./Navbar.module.css";

function NavbarLink({ item, onClick, disabled }) {
  if (disabled) {
    return (
      <div
        className={`${classes.link} ${classes.disabled}`}
        style={{ opacity: 0.5 }}
      >
        {item.icon && <item.icon stroke={1.5} className={classes.linkIcon} />}
        <span>{item.label}</span>
      </div>
    );
  }

  return (
    <NavLink
      to={item.path}
      key={item.label}
      className={({ isActive }) =>
        isActive ? `${classes.link} ${classes.active}` : classes.link
      }
      end
      onClick={onClick}
    >
      {item.icon && <item.icon stroke={1.5} className={classes.linkIcon} />}
      <span>{item.label}</span>
    </NavLink>
  );
}

export default NavbarLink;
