import { RichTextEditor, Link } from "@mantine/tiptap";
import { useEditor } from "@tiptap/react";
import Highlight from "@tiptap/extension-highlight";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Image from "@tiptap/extension-image";
import { Button, FileButton } from "@mantine/core";
import { IconPhoto } from "@tabler/icons-react";
import { useUpload } from "../../api/r2";
import { nanoid } from "nanoid";
import { toBase64 } from "../../services/helper";
import { useRef } from "react";

const PagesEditor = ({ content = null, onChange }) => {
  const { mutate } = useUpload();
  const resetRef = useRef();

  const uploadImage = async (file) => {
    const filename = nanoid(12);

    mutate(
      {
        name: filename,
        data: await toBase64(file),
      },
      {
        onSuccess: () => {
          editor
            .chain()
            .focus()
            .setImage({
              src: `https://pub-45c81e40f4c74a34bacddebce6171d1d.r2.dev/${filename}`,
            })
            .run();

          resetRef.current();
        },
      }
    );
  };

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      Image,
    ],
    content,
    onUpdate({ editor }) {
      onChange(JSON.stringify(editor.getJSON()));
    },
  });

  return (
    <RichTextEditor editor={editor} my="md">
      <RichTextEditor.Toolbar sticky stickyOffset={60}>
        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Bold />
          <RichTextEditor.Italic />
          <RichTextEditor.Underline />
          <RichTextEditor.Strikethrough />
          <RichTextEditor.ClearFormatting />
          <RichTextEditor.Highlight />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.H1 />
          <RichTextEditor.H2 />
          <RichTextEditor.H3 />
          <RichTextEditor.H4 />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Blockquote />
          <RichTextEditor.Hr />
          <RichTextEditor.BulletList />
          <RichTextEditor.OrderedList />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Link />
          <RichTextEditor.Unlink />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.AlignLeft />
          <RichTextEditor.AlignCenter />
          <RichTextEditor.AlignJustify />
          <RichTextEditor.AlignRight />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Undo />
          <RichTextEditor.Redo />
        </RichTextEditor.ControlsGroup>
        <FileButton onChange={uploadImage} resetRef={resetRef}>
          {(props) => (
            <Button size="compact-sm" radius="md" variant="default" {...props}>
              <IconPhoto size={16} />
            </Button>
          )}
        </FileButton>
      </RichTextEditor.Toolbar>

      <RichTextEditor.Content />
    </RichTextEditor>
  );
};

export default PagesEditor;
