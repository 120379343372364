import { Button, Paper } from "@mantine/core";
import { Controller, useForm } from "react-hook-form";
import { Checkbox, TextInput } from "react-hook-form-mantine";
import IconSelect from "../../IconSelect";
import PagesEditor from "../PagesEditor";
import { usePatchPage } from "../../../api/pages";

const EditPageForm = ({ data }) => {
  const defaultValues = {
    title: data?.title,
    icon: data?.icon,
    content: data?.content,
    draft: data?.draft,
  };

  const {
    control,
    handleSubmit,
    formState: { isDirty, dirtyFields },
    reset,
    watch,
  } = useForm({
    defaultValues,
  });

  const { mutate, isPending } = usePatchPage(data.id);

  const icon = watch("icon");

  const onSubmit = (data) => {
    const dirtyData = Object.keys(dirtyFields).reduce((acc, key) => {
      acc[key] = data[key];
      return acc;
    }, {});

    mutate(dirtyData, { onSuccess: reset({ ...defaultValues, ...dirtyData }) });
  };

  return (
    <Paper withBorder shadow="md" p="sm" mt="sm" radius="md">
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          label="Page title"
          placeholder="Sample page"
          control={control}
          name="title"
          my="md"
        />
        <IconSelect
          label="Icon"
          placeholder="Select icon"
          control={control}
          name="icon"
          currentIcon={icon}
        />
        <Controller
          name="content"
          control={control}
          render={({ field: { onChange, value } }) => (
            <PagesEditor onChange={onChange} content={JSON.parse(value)} />
          )}
        />
        <Checkbox
          label="Draft"
          control={control}
          name="draft"
          color="blue"
          size="md"
          my="md"
          description="Save page as draft. Draft pages are not visible to users."
        />
        <Button type="submit" disabled={!isDirty} loading={isPending}>
          Update page
        </Button>
      </form>
    </Paper>
  );
};

export default EditPageForm;
