// Helper function that converts file to base64
export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const fetchFromR2 = async (url) => {
  const data = await fetch(
    new Request(url, {
      method: "GET",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/json",
      },
    })
  );

  return data;
};

export const reduceWhiteSpaces = (string) => {
  return string.trim().replace(/\s\s+/g, " ");
};

export const ensureNoTrailingSlashAtEndOfUrl = (url) => {
  return url.replace(/\/+$/, "");
};
