import { Button } from "@mantine/core";
import { Link, useLocation, useMatches } from "react-router-dom";

import { ensureNoTrailingSlashAtEndOfUrl } from "../../services/helper";
import getActionButtonData from "./buttons";

const ActionButtons = () => {
  const matches = useMatches();

  const actions = matches
    // first get rid of any matches that don't have handle and crumb
    .filter((match) => Boolean(match.handle?.action))
    // then map them to crumb
    .map((match) => match.handle?.action);

  return (
    <>
      {actions.map((action) => (
        <ActionButton key={action} action={action} />
      ))}
    </>
  );
};

const ActionButton = ({ action }) => {
  const data = getActionButtonData(action);

  const location = useLocation();

  const locationPathname = ensureNoTrailingSlashAtEndOfUrl(location.pathname);

  return (
    <Button
      component={Link}
      to={locationPathname + data.to}
      leftSection={<data.icon />}
    >
      {data.label}
    </Button>
  );
};

export default ActionButtons;
