import { ajvResolver } from "@hookform/resolvers/ajv";
import {
  Button,
  Container,
  Fieldset,
  FileInput,
  Flex,
  Group,
  Image,
  Paper,
  Text,
  TextInput as TextInputMantine,
} from "@mantine/core";
import { IconFileUpload, IconPlus, IconX } from "@tabler/icons-react";
import { useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { TextInput } from "react-hook-form-mantine";
import { useUpload } from "../../../api/r2";
import { usePatchSponsor } from "../../../api/sponsors";
import { toBase64 } from "../../../services/helper";

function EditForm({ data, isSysAdmin }) {
  const { id } = data;
  const [sponsorLogo, setSponsorLogo] = useState(data.logo);

  const { mutate, isPending: isLoading, isSuccess } = usePatchSponsor(id);

  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { isDirty, dirtyFields },
  } = useForm({
    defaultValues: {
      name: data.name,
      subtitle: data.subtitle,
      description: data.description,
      logo: data.logo,
      links: data.links,
    },
    resolver: ajvResolver({
      type: "object",
      properties: {
        name: { type: "string", minLength: 3 },
        subtitle: { type: "string", minLength: 3 },
        description: { type: "string" },
      },
    }),
  });

  const { fields, append, remove } = useFieldArray({
    name: "links",
    control,
  });

  const { mutate: upload } = useUpload();

  const onSubmit = (data) => {
    if (dirtyFields.logo) {
      data.logo = `https://pub-45c81e40f4c74a34bacddebce6171d1d.r2.dev/${sponsorLogo.name}`;
    }
    mutate(data);
  };

  const currentLogo = getValues("logo");

  return (
    <Container size={500}>
      <Paper withBorder shadow="md" p={16} mt={16} radius="md">
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            label="Name"
            placeholder="Name"
            type="text"
            name="name"
            control={control}
            withAsterisk
            mt="md"
            disabled={!isSysAdmin}
          />
          <TextInput
            label="Subtitle"
            placeholder="Golden"
            type="text"
            name="subtitle"
            control={control}
            withAsterisk
            mt="md"
            disabled={!isSysAdmin}
          />
          <TextInput
            label="Description"
            placeholder="Description"
            type="text"
            name="description"
            control={control}
            mt="md"
            disabled={!isSysAdmin}
          />

          <Group>
            <FileInput
              label="Sponsor logo"
              name="logo"
              placeholder={data.logo.split("/").at(-1)}
              clearable
              rightSection={<IconFileUpload />}
              mt="md"
              style={{ flexGrow: 1 }}
              onChange={(value) => {
                setSponsorLogo(value);

                setValue(
                  "logo",
                  `https://pub-45c81e40f4c74a34bacddebce6171d1d.r2.dev/${value.name}`,
                  { shouldDirty: true }
                );
              }}
            />
            <Image src={currentLogo} w={80} ml={10} fit="contain" />
          </Group>
          <Fieldset legend="Links" mt="md">
            {fields.map((field, index) => (
              <Fieldset mt="md" key={field.id}>
                <TextInputMantine
                  label="Link label"
                  {...register(`links.${index}.label`)}
                />
                <TextInputMantine
                  label="Link URL"
                  {...register(`links.${index}.url`)}
                />

                <Button
                  mt={5}
                  size="xs"
                  style={{ backgroundColor: "red" }}
                  onClick={() => remove(index)}
                  leftSection={<IconX size={20} />}
                >
                  Remove link
                </Button>
              </Fieldset>
            ))}
            <Button
              mt={5}
              size="xs"
              style={{ justifySelf: "flex-end" }}
              onClick={() => append()}
              leftSection={<IconPlus size={20} />}
            >
              Add link
            </Button>
          </Fieldset>

          {!isSysAdmin && (
            <Text size="md" c="red" mt="md">
              Only system admins can create new sponsors!
            </Text>
          )}

          <Flex justify="space-between">
            <Button
              type="submit"
              mt="md"
              loading={isLoading}
              disabled={!isSysAdmin || !isDirty || isSuccess}
              onClick={async () => {
                if (!dirtyFields.logo) {
                  return;
                }

                upload({
                  name: sponsorLogo.name,
                  data: await toBase64(sponsorLogo),
                });
              }}
            >
              Edit sponsor
            </Button>
          </Flex>
        </form>
      </Paper>
    </Container>
  );
}

export default EditForm;
