import { Container } from "@mantine/core";
import { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { AuthContext } from "../services/context";
import EditProfile from "./EditProfile";
import LogOutButton from "./LogOutButton";
import Logo from "./Logo";

export default function GuardRoute() {
  const { user } = useContext(AuthContext);
  const location = useLocation();

  if (!user) {
    return <Navigate to="/" state={{ returnTo: location.pathname }} />;
  }

  if (user.role === "attendee") {
    return (
      <Container>
        <Logo style={{ width: 150, objectFit: "contain" }} />
        <EditProfile />
        <LogOutButton my="sm" mx="md" />
      </Container>
    );
  }

  return <Outlet />;
}
