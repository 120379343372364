import {
  ActionIcon,
  Card,
  Container,
  Flex,
  Group,
  Loader,
  Text,
  Title,
} from "@mantine/core";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { Link } from "react-router-dom";

import { useDeleteSession } from "../../api/session";

import deleteModal from "../deleteModal";
import { displayDateTime } from "./utils/displayDateTime";

export default function WorkspacesCards({ data, isLoading, role }) {
  const enableActions = role === "sysAdmin" || role === "wsAdmin";
  const { mutate, isError, isSuccess, error } = useDeleteSession();

  return isLoading ? (
    <Flex justify="center">
      <Loader m={10} />
    </Flex>
  ) : (
    <Container mt="md">
      {data.length === 0 && (
        <Flex justify="center">
          <Text size="sm" mt="sm">
            No sessions for this event
          </Text>
        </Flex>
      )}
      {data?.map((session) => (
        <Card shadow="sm" p="lg" radius="md" withBorder mb={5} key={session.id}>
          <Flex direction="row" justify="space-between">
            <Group display="block">
              <Title order={5}>{session.title}</Title>
              <Text size="sm" mt="sm">
                {session.location}
              </Text>
              <Text size="sm">
                Starting: {displayDateTime(session.starting)}
              </Text>
              <Text size="sm">Ending: {displayDateTime(session.starting)}</Text>
            </Group>

            <Group
              display="flex"
              style={{
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <ActionIcon size="md" component={Link} to={`${session.id}`}>
                <IconEdit />
              </ActionIcon>
              <ActionIcon
                size="md"
                disabled={!enableActions}
                onClick={() =>
                  deleteModal(
                    session,
                    `delete '${session.title}' session`,
                    mutate,
                    isError,
                    error,
                    isSuccess
                  )
                }
              >
                <IconTrash />
              </ActionIcon>
            </Group>
          </Flex>
        </Card>
      ))}
    </Container>
  );
}
