import { AppShell, Burger, Divider } from "@mantine/core";
import Logo from "../Logo";

import { UserButton } from "../UserButton";
import classes from "./Navbar.module.css";
import NavbarLink from "./NavbarLink";
import links from "./links";

function Navbar({ opened, toggle, user, isAuthenticated, error, isLoading }) {
  return (
    <AppShell.Navbar hidden={!opened} className={classes.navbar}>
      <div className={classes.header}>
        <Burger
          opened={opened}
          onClick={toggle}
          hiddenFrom="sm"
          size="sm"
          style={{ marginLeft: 10, marginTop: 10 }}
        />
        <Logo />
      </div>
      <div className={classes.navbarMain}>
        {links.map((item, index) => {

            return (
              <>
                <NavbarLink
                  item={item}
                  key={item.label}
                  onClick={toggle}
                  disabled={item.disabled}
                />
                {index === 2 && <Divider />}
              </>
            );
        })}
      </div>

      <div className={classes.footer}>
        {error && <p>Authentication failed</p>}
        <UserButton />
      </div>
    </AppShell.Navbar>
  );
}

export default Navbar;
