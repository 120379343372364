import { useAuth0 } from "@auth0/auth0-react";
import { ajvResolver } from "@hookform/resolvers/ajv";
import { Button, Container, Paper, Text } from "@mantine/core";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext, useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { Checkbox, Select, TextInput, Textarea } from "react-hook-form-mantine";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useGetPoll, useUpdatePoll } from "../../api/polls";
import { useGetSessions } from "../../api/session";
import { AuthContext } from "../../services/context";
import { getCurrentWorkspace } from "../../services/userConfig";

export default function EditPoll() {
  const { user } = useContext(AuthContext);
  const { id } = useParams();
  const isSysAdmin = user?.role === "sysAdmin";
  const { data, isLoading } = useGetPoll(id);
  const currentWorkspace = getCurrentWorkspace(user);
  const { data: sessionsData } = useGetSessions({
    workspaceId: currentWorkspace.id,
    eventId: currentWorkspace.eventId,
  });
  const { mutate } = useUpdatePoll({
    id,
    ...data,
  });

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      title: data?.title,
      description: data?.description,
      isVisible: data?.isVisible,
      status: data?.status,
      areResultsVisible: data?.areResultsVisible,
      options: data?.options,
    },
    resolver: ajvResolver({
      type: "object",
      properties: {
        title: { type: "string", minLength: 1 },
        description: { type: "string" },
        options: {
          type: "array",
          items: {
            type: "object",
            properties: {
              id: { type: "string", minLength: 1 },
              title: { type: "string" },
            },
            required: ["title", "id"],
          },
        },
      },
      required: ["title"],
    }),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "options",
  });

  useEffect(() => {
    reset({ options: data?.options });
  }, [data?.options, reset]);

  const onSubmit = (data) => {
    if (isSysAdmin) {
      mutate({ id, ...data });
    }
  };

  if (isLoading) return <Text>Loading...</Text>;

  return (
    <Container size={500}>
      <Paper withBorder shadow="md" p={16} mt={16} radius="md">
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            label="Question"
            placeholder="Edit question"
            type="text"
            name="title"
            withAsterisk
            defaultValue={data?.title}
            mt="md"
            control={control}
            disabled={!isSysAdmin}
          />
          <Textarea
            label="Description"
            placeholder="Description"
            type="text"
            name="description"
            defaultValue={data?.description}
            control={control}
            mt="md"
            disabled={!isSysAdmin}
          />
          <Select
            control={control}
            label="Status"
            name="status"
            data={["open", "closed"]}
            defaultValue={data?.status}
          />
          <Select
            control={control}
            label="Session"
            name="sessionId"
            data={sessionsData?.map((s) => ({
              value: `${s.id}`,
              label: s.title,
            }))}
            defaultValue={`${data?.sessionId}`}
          />
          <Checkbox
            label="Is Visible"
            name="isVisible"
            mt="md"
            defaultChecked={data?.isVisible}
            control={control}
          />
          <Checkbox
            label="Results Visible"
            name="areResultsVisible"
            mt="md"
            defaultChecked={data?.areResultsVisible}
            control={control}
          />
          <div style={{ marginTop: 20 }}>
            <label>Options</label>
            {fields.map((field, index) => (
              <div key={field.id} style={{ display: "flex", marginBottom: 20 }}>
                <Button
                  color="gray"
                  type="button"
                  onClick={() => remove(index)}
                  disabled={fields.length === 2}
                >
                  -
                </Button>
                <Button
                  color="gray"
                  style={{ marginLeft: 10, marginRight: 10 }}
                  type="button"
                  onClick={() => append({ id: uuidv4(), title: "" })}
                >
                  +
                </Button>
                <TextInput
                  name={`options.${index}.id`}
                  value={field.id}
                  control={control}
                  type="hidden"
                  defaultValue={field.id}
                />
                <TextInput
                  placeholder="Option"
                  type="text"
                  name={`options.${index}.title`}
                  control={control}
                  defaultValue={field.title}
                />
              </div>
            ))}
          </div>
          <Button type="submit" mt="md" disabled={!isSysAdmin}>
            Update Poll
          </Button>
        </form>
      </Paper>
    </Container>
  );
}
