import React, { createContext, useState } from "react";
import { useGetCurrentUser } from "../api/user";

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState();
  const { data } = useGetCurrentUser();

  React.useEffect(() => {
    const token = localStorage.getItem("token");

    if (token && data != null) {
      setUser(data);
    } else {
      setUser(null);
    }
  }, [data]);

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};
