import { ActionIcon, Badge, Group, Switch, Text, Tooltip } from "@mantine/core";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { DataTable } from "mantine-datatable";
import { Link } from "react-router-dom";
import { useContext, useState } from "react";
import { AuthContext } from "../../services/context";

import { useDeleteSession, usePollsToggle } from "../../api/session";
import { useGetSponsors } from "../../api/sponsors";
import { getCurrentWorkspace } from "../../services/userConfig";
import { useGetUsers } from "../../api/user";
import deleteModal from "../deleteModal";
import { displayDateTime } from "./utils/displayDateTime";

const PollToggleSwitch = ({ sessionId, defaultChecked }) => {
  const [isChecked, setIsChecked] = useState(defaultChecked);
  const { mutate: switchMutate, isPending } = usePollsToggle();

  const handleTogglePolls = () => {
    const newCheckedState = !isChecked;

    setIsChecked(newCheckedState);
    switchMutate(sessionId, {
      onError: () => {
        setIsChecked(defaultChecked);
      },
    });
  };

  return (
    <Switch
      color="green"
      defaultChecked={defaultChecked}
      onLabel="ON"
      checked={isChecked}
      offLabel="OFF"
      onChange={() => handleTogglePolls()}
      disabled={isPending}
    />
  );
};

export default function SessionsTable({
  data,
  isLoading,
  role,
  page,
  setPage,
}) {
  const { user } = useContext(AuthContext);
  const currentWorkspace = getCurrentWorkspace(user);
  const enableActions = role === "sysAdmin" || role === "wsAdmin";
  const { mutate, isError, isSuccess, error } = useDeleteSession();
  const { mutate: switchMutate } = usePollsToggle();
  const { data: userData } = useGetUsers();
  const { data: sponsorsData } = useGetSponsors({
    workspaceId: currentWorkspace?.id,
    eventId: currentWorkspace?.eventId,
  });

  return (
    <DataTable
      records={data?.data}
      columns={[
        {
          accessor: "title",
          title: "Title",
          render: (session) => (
            <>
              <Text size="sm">{session.title}</Text>
              {session.canceledAt !== null && (
                <Badge color="red" variant="light" size="sm">
                  Canceled
                </Badge>
              )}
            </>
          ),
        },
        {
          accessor: "description",
        },
        {
          accessor: "starting",
          render: (session) => displayDateTime(session.starting),
        },
        {
          accessor: "ending",
          render: (session) => displayDateTime(session.ending),
        },
        { accessor: "location" },
        {
          accessor: "sponsorId",
          title: "Sponsor",
          render: (session) => {
            const sponsorInfo = sponsorsData?.data.find(
              (sp) => sp.id === session.sponsorId
            );
            return (
              sponsorInfo && (
                <>
                  <Text size="sm">{sponsorInfo?.name}</Text>
                  <Text size="xs" opacity={0.5}>
                    {sponsorInfo?.subtitle}
                  </Text>
                </>
              )
            );
          },
        },
        {
          accessor: "speakers",
          render: (session) =>
            session.speakers?.list?.map((speaker, index) => {
              const speakerInfo = userData.find((user) => user.id === speaker);
              const last = session.speakers.list.length - 1;
              return (
                <Group
                  key={`${speakerInfo?.firstName}-${speakerInfo?.lastName}`}
                >
                  <Text size="sm" pb={index !== last ? 10 : 0}>
                    {speakerInfo?.firstName} {speakerInfo?.lastName},{" "}
                    {speakerInfo?.occupation}
                  </Text>
                </Group>
              );
            }),
        },
        {
          accessor: "links",
          render: (session) =>
            session.links?.list?.map((link, index) => {
              const last = session.links?.list?.length - 1;
              return (
                <Group key={`${link?.title}`}>
                  <Text
                    size="sm"
                    style={{
                      textDecorationLine: "underline",
                    }}
                    component={Link}
                    to={
                      link?.url.startsWith("http://") ||
                      link?.url.startsWith("https://")
                        ? link?.url
                        : `//${link?.url}`
                    }
                    pb={index !== last ? 10 : 0}
                  >
                    {link.title}
                  </Text>
                </Group>
              );
            }),
        },
        {
          accessor: "activePolls",
          title: "Polls",
          render: (session) => (
            <PollToggleSwitch
              sessionId={session.id}
              defaultChecked={session.activePolls}
            />
          ),
        },
        {
          accessor: "actions",
          width: 100,
          render: (session) => (
            <Group justify="center">
              <Tooltip label="Edit">
                <ActionIcon
                  size="md"
                  variant="light"
                  component={Link}
                  to={`${session.id}`}
                >
                  <IconEdit />
                </ActionIcon>
              </Tooltip>
              <Tooltip label="Delete">
                <ActionIcon
                  size="md"
                  variant="light"
                  disabled={!enableActions}
                  color="red"
                  onClick={() => {
                    deleteModal(
                      session,
                      `delete '${session.title}' session`,
                      mutate,
                      isError,
                      error,
                      isSuccess
                    );
                  }}
                >
                  <IconTrash />
                </ActionIcon>
              </Tooltip>
            </Group>
          ),
        },
      ]}
      withTableBorder
      striped
      withColumnBorders
      highlightOnHover
      page={page}
      onPageChange={setPage}
      recordsPerPage={data?.pagination.perPage}
      totalRecords={data?.pagination.total}
      fetching={isLoading}
      minHeight={150}
    />
  );
}
