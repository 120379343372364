/**
 * Renders a component for searching users and displaying them in a select dropdown.
 *
 * @component
 * @param {import("react-hook-form-mantine").SelectProps} props - The props object containing the component's properties.
 * @returns {JSX.Element} The rendered component.
 */
import { Loader, Text } from "@mantine/core";
import { Select } from "react-hook-form-mantine";

import { useGetUsers } from "../../../api/user";

// TODO: Wanted to keep this as minimal as possible.
// Async search isn't working as expected, and I don't want to spend too much time on this.
// Implementing this in the future would be a good idea.
export default function SearchUsers(props) {
  const { data: users, isPending, isError, error } = useGetUsers();

  const options = users?.map((user) => ({
    value: `${user.id}`,
    label: `${user.firstName} ${user.lastName}`,
  }));

  if (isError) return <Text c="red">{error.message}</Text>;

  return (
    <Select
      data={options}
      searchable
      rightSection={isPending ? <Loader size="1rem" /> : null}
      clearable
      {...props}
    />
  );
}
