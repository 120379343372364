import { useApiMutation, useApiQuery } from "../services/react-query";

export const useGetEvents = (params) =>
  useApiQuery({
    queryOptions: { queryKey: ["events"] },
    fetchOptions: { url: "/events", method: "GET", params },
  });

export const useGetEvent = (id) =>
  useApiQuery({
    queryOptions: { queryKey: ["event", { id }], enabled: !!id },
    fetchOptions: { url: `/events/${id}`, method: "GET" },
  });

export const useCreateEvent = () =>
  useApiMutation({
    mutationOptions: { mutationKey: "useCreateEvent" },
    fetchOptions: { url: "/events", method: "POST" },
    invalidateQueryKey: "events",
    successMessage: { title: "Event has been created", color: "green" },
  });

export const usePatchEvent = (id) =>
  useApiMutation({
    mutationOptions: { mutationKey: "usePatchEvent", enabled: !!id },
    fetchOptions: { url: `/events/${id}`, method: "PATCH" },
    invalidateQueryKey: "events",
    successMessage: { title: "Event has been updated", color: "green" },
  });

export const useDeleteEvent = () =>
  useApiMutation({
    mutationOptions: { mutationKey: "useDeleteEvent" },
    fetchOptions: { url: "/events", method: "DELETE" },
    invalidateQueryKey: "events",
    successMessage: { title: "Event has been deleted", color: "green" },
  });
