import { useApiMutation, useApiQuery } from "../services/react-query";

export const useGetPolls = (params) =>
  useApiQuery({
    queryOptions: { queryKey: ["polls", params] },
    fetchOptions: {
      url: "/polls",
      method: "GET",
      params,
    },
  });

export const useGetPoll = (pollId) =>
  useApiQuery({
    queryOptions: { queryKey: ["poll", pollId], enabled: !!pollId },
    fetchOptions: { url: `/polls/${pollId}`, method: "GET" },
  });

export const useGetPollResults = (pollId) => {
  return useApiQuery({
    queryOptions: { queryKey: ["pollResults", pollId], enabled: !!pollId },
    fetchOptions: { url: `/polls/${pollId}/results`, method: "GET" },
  });
};
export const useCreatePoll = (data) => {
  return useApiMutation({
    mutationOptions: { mutationKey: "useCreatePoll", data },
    fetchOptions: { url: "/polls", method: "POST" },
    invalidateQueryKey: "polls",
    successMessage: { title: "Poll has been created", color: "green" },
  });
};

export const useUpdatePoll = (data) => {
  return useApiMutation({
    mutationOptions: { mutationKey: "useUpdatePoll", data },
    fetchOptions: {
      url: `/polls/${data.id}`,
      method: "PATCH",
    },
    invalidateQueryKey: "polls",
    successMessage: {
      title: "Poll has been updated",
      color: "green",
    },
    data,
  });
};

export const useDeletePoll = (id) => {
  return useApiMutation({
    mutationOptions: { mutationKey: "useDeletePoll", id },
    fetchOptions: {
      url: `/polls/${id}`,
      method: "DELETE",
    },
    invalidateQueryKey: "polls",
    successMessage: {
      title: "Poll has been deleted",
      color: "green",
    },
  });
};
