import { useAuth0 } from "@auth0/auth0-react";
import { AppShell } from "@mantine/core";

import { useDisclosure } from "@mantine/hooks";
import { Outlet } from "react-router-dom";

import Header from "../components/Header/Header";
import Navbar from "../components/Navbar/Navbar";

export default function App() {
  const { isLoading, error, user, isAuthenticated } = useAuth0();
  const [opened, { toggle }] = useDisclosure();

  return (
    <AppShell
      navbar={{
        width: 280,
        breakpoint: "sm",
        collapsed: { mobile: !opened },
      }}
      padding="md"
      header={{
        height: 60,
      }}
      layout="alt"
    >
      <Header opened={opened} toggle={toggle} />
      <Navbar
        opened={opened}
        toggle={toggle}
        user={user}
        isLoading={isLoading}
        error={error}
        isAuthenticated={isAuthenticated}
      />

      <AppShell.Main>
        <Outlet />
      </AppShell.Main>
    </AppShell>
  );
}
