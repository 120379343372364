// Default configuration for development environment
export const webConfig = {
  baseUrl: "http://localhost:5173",
  apiUrl: "http://localhost:3000/api",
  posthog: {
    key: "phc_BweFCepcCq0UckpOWouvf7EHn70MgMqh8LhGSRphag9",
    host: "https://eu.posthog.com",
  },
  auth0: {
    domain: "dev-shi48vsn8qo4kr7d.eu.auth0.com",
    clientId: "KXHfUlexnHxIUSgPmFcd2v8y5zk1vA07",
    audience: "example-api",
  },
  r2: {
    myBucket: "congrs",
  },
  // Sentry dsn and token in env
};
