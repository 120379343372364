import { useContext, useState } from "react";

import { useGetPolls } from "../api/polls";
import PollsTable from "../components/Polls/PollsTable";
import { AuthContext } from "../services/context";
import { getCurrentWorkspace } from "../services/userConfig";

function Polls() {
  const { user } = useContext(AuthContext);
  const [page, setPage] = useState(1);
  const currentWorkspace = getCurrentWorkspace(user);

  const { data, isPending: isLoading } = useGetPolls({
    page,
    workspaceId: currentWorkspace.id,
    eventId: currentWorkspace.eventId,
  });

  return (
    <PollsTable
      data={data}
      isLoading={isLoading}
      role={user.role}
      page={page}
      setPage={setPage}
    />
  );
}

export default Polls;
