import { Avatar, Button, Container, Fieldset } from "@mantine/core";
import { useForm } from "react-hook-form";
import { Checkbox, TextInput, Textarea } from "react-hook-form-mantine";

import { useGetCurrentUser, useUpdateCurrentUser } from "../api/user";
import DeleteProfilePicture from "./Buttons/DeleteProfilePicture";
import DeleteAccountButton from "./Users/DeleteAccountButton";

const EditProfile = () => {
  const { data } = useGetCurrentUser();

  const { mutate, isPending: mutatePending } = useUpdateCurrentUser();

  // TODO: Reset form after successful submission
  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      firstName: data?.firstName || "",
      lastName: data?.lastName || "",
      email: data?.email || "",
      occupation: data?.occupation || "",
      biography: data?.biography || "",
      discoverable: data?.discoverable || false,
    },
  });

  const onSubmit = (data) => {
    mutate(data);
  };

  return (
    <Container>
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        <Fieldset legend="Profile picture">
          <Avatar
            src={data?.picture}
            size={150}
            radius="xl"
            alt="Profile picture"
          />
          {data?.picture && (
            <DeleteProfilePicture userId={data?.id} my="md" size="sm" />
          )}
        </Fieldset>

        <Fieldset legend="Personal information">
          <TextInput
            name="firstName"
            control={control}
            placeholder="John"
            label="First name"
          />
          <TextInput
            mt="md"
            name="lastName"
            control={control}
            placeholder="Doe"
            label="Last name"
          />
          <TextInput
            mt="md"
            name="email"
            control={control}
            disabled
            label="Email"
          />
          <TextInput
            mt="md"
            name="occupation"
            control={control}
            placeholder="Software Engineer @ RoundCube"
            label="Occupation and company"
          />
          <Textarea
            mt="md"
            name="biography"
            control={control}
            placeholder="Tell us about yourself"
            label="Biography"
          />
          <Checkbox
            mt="md"
            name="discoverable"
            control={control}
            label="Discoverable"
            description="Allow other users to find you by searching your name"
          />
        </Fieldset>
        <Button
          my="md"
          type="submit"
          variant="light"
          disabled={!isDirty}
          loading={mutatePending}
        >
          Save changes
        </Button>
      </form>
      <DeleteAccountButton />
    </Container>
  );
};

export default EditProfile;
