import { ActionIcon, Group, Text, Tooltip } from "@mantine/core";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { DataTable } from "mantine-datatable";
import { Link } from "react-router-dom";
import { useDeleteEvent } from "../../api/event";
import deleteModal from "../deleteModal";

function EventsTable({ data, isLoading, isSysAdmin, page, setPage }) {
  const { mutate, isError, isSuccess, error } = useDeleteEvent();

  return (
    <>
      <DataTable
        records={data?.data}
        columns={[
          { accessor: "name" },
          {
            accessor: "actions",
            width: 100,
            render: (event) => (
              <Group justify="center">
                <Tooltip label="Edit">
                  <ActionIcon
                    size="md"
                    variant="light"
                    component={Link}
                    to={`${event.id}`}
                  >
                    <IconEdit />
                  </ActionIcon>
                </Tooltip>
                <Tooltip label="Delete">
                  <ActionIcon
                    size="md"
                    variant="light"
                    color="red"
                    disabled={!isSysAdmin}
                    onClick={() => {
                      deleteModal(
                        event,
                        `delete '${event.name}' event`,
                        mutate,
                        isError,
                        error,
                        isSuccess
                      );
                    }}
                  >
                    <IconTrash />
                  </ActionIcon>
                </Tooltip>
              </Group>
            ),
          },
        ]}
        withTableBorder
        striped
        withColumnBorders
        highlightOnHover
        page={page}
        onPageChange={setPage}
        recordsPerPage={data?.pagination.perPage}
        totalRecords={data?.pagination.total}
        fetching={isLoading}
      />
    </>
  );
}

export default EventsTable;
