import { Button } from "@mantine/core";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "../services/context";
import { queryClient } from "../services/react-query";

import { webConfig } from "../../config";

const LogOutButton = ({ ...props }) => {
  const navigate = useNavigate();

  const { user, setUser } = useContext(AuthContext);
  return (
    user && (
      <Button
        {...props}
        onClick={() => {
          localStorage.removeItem("token");
          setUser(null);
          queryClient.invalidateQueries("auth");
          navigate("/");
        }}
      >
        Sign out
      </Button>
    )
  );
};
export default LogOutButton;
