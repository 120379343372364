import { useMediaQuery } from "@mantine/hooks";
import { useContext, useState } from "react";

import { useGetSessions } from "../api/session";
import SessionsCards from "../components/Sessions/SessionsCards";
import SessionsTable from "../components/Sessions/SessionsTable";
import { AuthContext } from "../services/context";
import { getCurrentWorkspace } from "../services/userConfig";

function Sessions() {
  const { user } = useContext(AuthContext);
  const smallScreen = useMediaQuery("(max-width: 640px)");
  const [page, setPage] = useState(1);

  const currentWorkspace = getCurrentWorkspace(user);
  const eventId = currentWorkspace.eventId;
  const workspaceId = currentWorkspace.id;

  const sessionParams = smallScreen
    ? { eventId, workspaceId }
    : { page, eventId, workspaceId };

  const { data, isPending: isLoading } = useGetSessions(sessionParams);

  return (
    <>
      {smallScreen ? (
        <SessionsCards data={data} isLoading={isLoading} role={user.role} />
      ) : (
        <SessionsTable
          data={data}
          isLoading={isLoading}
          role={user.role}
          page={page}
          setPage={setPage}
        />
      )}
    </>
  );
}

export default Sessions;
